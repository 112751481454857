
import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Seo from "./../../components/seo"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import MarkedButton from "../../components/btn/MarkedButton";
import { ArchiveSingle } from "../../components/dialogi/ArchiveSingle";

import { GridContainer, Container } from "../../grid"
import { useTheme } from '@mui/material/styles';
import Button from "@mui/material/Button"



const Dialogi = ({data}) => {
  
  // console.log(data)

  const theme = useTheme()

  const active = data.active.edges[0].node
  const lastIssues = data.last.edges
  const image = getImage(active.cover3d.localFile)

  return (
    <>
      <Seo title="Dialogi" />
      
      {/* Hero Area */}
      <Box component='section' sx = { (theme) => ({
        backgroundColor: theme.palette.white,
      })}>
        <Container 
          pt={{
            xs: 4,
            md: 4
          }}
        >
          <GridContainer 
            alignItems='center'
            columnSpacing={{ 
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
              xl: 0
            }}
          >
            <Grid item xs={12} md2={5}>
              <Box sx = { (theme) => ({
                maxWidth: {
                  xs: 400,
                  lg: 'none'
                }
              })}>
                <GatsbyImage
                  image={image}
                  alt={active.title}
                  loading="eager"
                />
              </Box>
            </Grid>
            <Grid xs={12} md2={7} xl={6} item sx = { (theme) => ({
              display: 'grid',
              gap: {xs:5, sm:6},
              mt: {
                xs: 4
              }
            })}>
                <Box>
                  <Typography variant="bookAuthor">Zadnja številka</Typography>
                  <Typography variant="bookTitle">{active.title}</Typography>
                </Box>
                <Box>
                  <Box dangerouslySetInnerHTML={{__html: active.description.html}}></Box>
                </Box>

                <Box>
                  <MarkedButton to={`/dialogi/${active.leto.year}/${active.stevilka}`}>Več o zadnji številki</MarkedButton>
                </Box>
                
            </Grid>
          </GridContainer>  
        </Container>
      </Box>
      
      {/* Zadnje številke */}
      <Box component='section' sx = { (theme) => ({
        backgroundColor: theme.palette.grey1,
        mt: {
          lg: 8
        }
      })}>
        <Container>
            <Typography sx = { (theme) => ({
              textAlign: 'center'
            })} variant='h2' mb={9}>Prejšnje številke</Typography>
            <Box sx = { (theme) => ({
              mt: 10,
              justifyContent: 'center',
              display: 'grid',
              columnGap: {
                xs: theme.spacing(4),
                xl: theme.spacing(8)
              },
              rowGap:{
                xs: theme.spacing(8),
                md: theme.spacing(10)
              },
              gridTemplateColumns: {
                xs: '1fr 1fr',
                xl: '1fr 1fr 1fr 1fr'
              }
            })}>
              {lastIssues.map((issue) => <ArchiveSingle key={issue.node.id} book={issue.node} color={theme.palette.grey1}/>)}
            </Box>
            <Box sx = { (theme) => ({
              display: 'flex',
              justifyContent: 'center',
              mt: 8
            })}>
              <Button 
                variant='outlined'
                component={Link}
                to={`/dialogi/${active.leto.year}`}
              >
                Poglej celotni arhiv
              </Button>
            </Box>
        </Container>
      </Box>
    </>
  )
}

export default Dialogi

export const query = graphql`
  query DialogiHome {
  active: allStrapiDialogi(filter: {aktualna: {eq: true}}) {
    edges {
      node {
        id
        strapi_id
        stevilka
        title
        leto {
          color
          year
          number
          textColor
        }
        description: childStrapiDialogiDescriptionTextnode {
          html: description
        }
        cover3d {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  }
  last: allStrapiDialogi(
    filter: {aktualna: {eq: false}}
    limit: 4
    sort: {fields: leto___year, order: DESC}
  ) {
    edges {
      node {
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        leto {
          color
          year
          number
        }
        id
        strapi_id
        pdf {
          url
        }
        prices {
          online
          regular
          discount
        }
        stevilka
        title
      }
    }
  }
}
`