import React from 'react';
import { Box } from '@mui/system';

export default function CelotnaLabel({size = {
  xs: '4rem',
  md2: '6rem'
}, onClick }) {
  return (
    <Box 
      onClick={onClick}
      sx = { (theme) => ({
        width: size,
        height: size,
        bgcolor: theme.palette.black,
        borderRadius: '50%',
        color: theme.palette.white,
        textAlign: 'center',
        fontSize: size,
        lineHeight: '1.25',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: 0,
        bottom: '-1rem',
        zIndex: 10,

        '& span': {
          fontWeight: 600,
          fontSize: '1.1em'
        },
        '& p': {
          margin: 0,
          fontSize: '0.12em'
        }
    })}>
      <p>Na voljo <span>celotna številka</span><br/> (PDF)</p>
    </Box>
  )
};
