import React, { useContext, useState, forwardRef } from 'react';
import { navigate } from 'gatsby';

import { Cover } from './../books/Cover';
import { cartContext } from '../../contexts';
import { addToCart } from '../../contexts/actions';
import {ArchiveBtn} from './../books/ArchiveBtn';
import {CartIcon, MoreIcon} from './../icons'

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Transition } from 'react-transition-group';
import DialogiLabel from './DialogiLabel';
import CelotnaLabel from './CelotnaLabel';

export const ArchiveSingle = forwardRef((props, ref) => {
  
  const data = props.book 
  // console.log(data)

  const {cart, dispatchCart} = useContext(cartContext)

  const [hover, setHover] = useState(false)
  const [clickable, setClickable] = useState(false)
  const [inCart, setInCart] = useState(
    cart.findIndex( item => item.id === data.strapi_id ) !== -1
  )
  
  const clickHandler = () => {
    navigate(`/dialogi/${data.leto.year}/${data.stevilka}`)
  }
  const addCartHandler = ({id, title, prices, cover, slug}) => {
    setInCart(true)
    dispatchCart(addToCart({
      id,
      title,
      prices,
      cover,
      author: `Dialogi ${data.stevilka}-${data.leto.year}`,
      slug,
      type: 'dialogi'
    }, 1))
  }
  return (
  <div ref={ref} {...props} >
    <Transition in={hover} timeout={200}>
      {
        (state) => (
          <Box 
            sx = { (theme) => ({
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: {
                xs: 4,
                md: 6,
                lg: 6,
                // xl: 8
              },
            })} 
            onMouseEnter={() => {
              setHover(true)
              setInterval(()=>setClickable(true), 500)
            }}
            onMouseLeave={() => {
              setHover(false)
              setClickable(false)
            }}
            className={`${state}`}
          >
            <Box sx = { (theme) => ({
              width: '100%',
              flexShrink: 0,
              position: 'relative',
              zIndex: 5,
            })}>
              
              <Box sx = { (theme) => ({
                display: 'flex',
                flexDirection: 'column',
                '&>div': {
                  width: '100%',
                  flex: '0 1 auto',
                  justifyContent: 'center',
                },
                gap: {
                  xs: theme.spacing(3),
                },
                position: 'absolute',
                right: {
                  xs: theme.spacing(2),
                  lg: theme.spacing(4)
                },
                top: {
                  xs: 0,
                  lg: theme.spacing(4)
                },
                left: {
                  xs: theme.spacing(2),
                  lg: theme.spacing(4)
                },
              })}>
              { data.leto.year >= 2018 && <ArchiveBtn 
                  onClick={()=>{
                    if (inCart) {
                      navigate('/kosarica')
                    } else {
                      addCartHandler({
                        id: data.strapi_id,
                        title: data.title,
                        author: data.author,
                        prices: data.prices,
                        cover: data.cover.localFile,
                        slug: `/dialogi/${data.leto.year}/${data.stevilka}`
                        
                      })
                    }
                  }} 
                  delay={0} state={state} 
                  icon={<CartIcon/>}
                  badge={inCart}
                >
                    { inCart ? 'Zaključi nakup' : 'Dodaj v košarico' }
                </ArchiveBtn>}
                
                <ArchiveBtn
                  onClick={clickHandler} 
                  delay={0} 
                  state={state} 
                  icon={<MoreIcon/>}>Več o številki</ArchiveBtn>
              </Box>

              {data.pdf && <CelotnaLabel onClick={clickHandler} />}
              
              <Cover
                onClick={() => {
                  clickable && clickHandler()
                }}
                img={data.cover.localFile} 
                alt={data.title} 
                gatsbyimageprops={{
                  loading: 'lazy'
                }}
                sx = {((theme) => ({
                  transition: `transform 0.25s ${theme.transitions.easing.easeOutCubic} 0.15s`,
                  transform: state === 'entered' ? 'translateY(6rem)' : 'translateY(0)',
                  zIndex: 5,
                  width: '90%',
                }))}
              />
            </Box>
            <Box sx = { (theme) => ({
              backgroundColor: props.color ? props.color : theme.palette.white,
              position: 'relative',
              zIndex: 10,
              width: 'calc( 100% + 1rem)',
              minHeight: '6rem',
              pt: {xs: 1 },
              ml: {xs: -2},
              pl: {xs: 3}
            })}>
                <DialogiLabel 
                  stevilka={data.stevilka} 
                  year={data.leto.year} 
                  sx = { (theme) => ({
                    m: 0,
                    fontSize: {
                      xs: '1.75rem',
                      lg: '2rem'
                    }
                  })}
                />
                <Typography variant='bookArchiveTitle'>{data.title}</Typography>                
            </Box>
          </Box>
        )
      }
      
    </Transition>
  </div>
  )
});