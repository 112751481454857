import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';

const MarkedLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "darker",
})(
  ({ theme, darker }) => `
    color: var(--black);
    border-radius: 0;
    padding-bottom: 0.5em !important;
    line-height: 2rem;
    display: inline-block !important;
    margin: 0;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.875rem;

    & span {
      position: relative;
      z-index: 10;
    }
   
    &::after {
      z-index: 1;
      content: "";
      position: absolute;
      bottom: 35%;
      left: 0;
      pointer-events: none;
      display: block;
      width: 100%;
      height: 0.6em;
      background-color: ${darker ? 'var(--grey4)' : 'var(--grey3)'};
      transform-origin: 0 50%;
      transform: scaleY(0);
      transition: transform 0.3s;
      // transform: scaleY(1);
    }
    &:hover::after {
      transform: scaleY(1)
      // transform: scaleY(0)
    }
`)

export default function MarkedButton(params) {
  return (
    <MarkedLink {...params}>
      <span>{params.children}</span>
    </MarkedLink>
  )
}; 
